.App {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.column {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-evenly;
  background-color: grey;
}

.fullColumn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-evenly;
  background-color: grey;
  animation: columnFull;
  animation-duration: 0.5s;
}

.emptyColumn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-evenly;
  background-color: grey;
  animation: columnEmpty;
  animation-duration: 0.5s;
}

.boardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@keyframes columnFull {
  0% {background-color: red;}
  100% {background-color: grey;}
}
@keyframes columnEmpty {
  0% {background-color: green;}
  100% {background-color: grey;}
}