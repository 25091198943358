.Preview {
  position: relative;
}

.Preview:hover {
  cursor: pointer;
}

.PreviewImage {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

p.PreviewTitle {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 3px 2px 8px #000000;
}

p.PreviewDate {
  position: absolute;
  font-size: 13px;
  right: 20px;
  bottom: 20px;
  text-shadow: 3px 2px 8px #000000;
}