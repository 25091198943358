.App {
  background-color: #fff;
  text-align: center;
}

.Screen {
  width: 80%;
  text-align: center;
  margin: auto;
}

.Absolute-header {
  position: sticky;
  z-index: 10;
  top: 0;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,0,0,0));
}

.App-header {
  min-height: 100vh;
  max-width: 80vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.container {
  text-align: center;
  background-color: #ff0000;
}

.bp-link {
  color: #61dafb;
}
