.Header {
  position: relative;
  top: 0;
  text-align: center;
  background-color: #282c34;
  height: 300px;
  width: 100%;
  overflow: hidden;
  background-image: url("../../assets/hubble.jpg");
  background-attachment: fixed;
  background-size: cover;
}

.Header-image {
  width: 100%;
}

.Header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 28px;
  color: white;
  text-shadow: 4px 4px 8px #000000;
}

.Header-bar {
  position: fixed;
  top: 0;
  height: 50px;
  width: 100%;
  background-color: rgba(255,255,255,0.8);
  z-index: 10;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
}

.Header-bar-button {
  text-align: center;
  font-size: 36;
  width: 150px;
  height: 100%;
  border: none;
  background-color: transparent;
  color: black;
  text-decoration: none;
  transition-duration: 0.4s;
  float: left;
  outline: none;
}

.Header-bar-button:focus {
  outline: none;
  border: none;
}

.Header-bar-button:hover {
  background-color: rgba(255,255,255,0.95);
}

a.fill-div {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
}